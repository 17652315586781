import {parseGid} from '@shopify/admin-graphql-api-utilities';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {v4 as uuidv4} from 'uuid';

import {useSharedDataContext} from '~/foundation/AppSetupContext';

// don't ask why we can't import from ~/hooks directly
import {useSessionStorage} from '../../hooks/useStorage';

const SessionIdContext = createContext<
  | {
      getSessionId: () => string;
    }
  | undefined
>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}

export function SessionIdContextProvider({children}: ProviderProps) {
  const {userId, shop} = useSharedDataContext();
  const shopId = parseGid(shop.id);

  const getNewSessionExpiration = () => {
    const halfHourInMilliseconds = 30 * 60 * 60 * 1000;
    return Date.now() + halfHourInMilliseconds;
  };

  const [sessionExpiration, setSessionExpiration] = useSessionStorage(
    `SessionId.expiration`,
    getNewSessionExpiration,
  );

  const sessionExpirationRef = useRef(sessionExpiration);

  /*
   * useSessionStorage is based on useState. Each tracking event calls getSessionId which needs to update the expiration (to keep the session active)
   * At the end that means that calling track updates sessionExpiration invalidating the memoization of any function that depends on that,
   * which will bubble up to cause a refresh in the component and in some cases that could cause a new call to track, creating a loop.
   * Using a ref to avoid depending on sessionExpiration directly is a way to break that loop
   */
  const updateSessionExpiration = useCallback(() => {
    const newExpiration = getNewSessionExpiration();
    sessionExpirationRef.current = newExpiration;
    setSessionExpiration(newExpiration);
  }, [setSessionExpiration]);

  // Use the userId and shopId on the key name to change the token if the user or shop changes
  const [sessionId, setSessionId] = useSessionStorage(
    `SessionId.${userId}.${shopId}`,
    uuidv4,
  );

  const value = useMemo(
    () => ({
      getSessionId: () => {
        const sessionExpired = sessionExpirationRef.current <= Date.now();
        updateSessionExpiration();
        if (sessionId && !sessionExpired) {
          return sessionId;
        }
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
        return newSessionId;
      },
    }),
    [sessionId, setSessionId, updateSessionExpiration],
  );
  return (
    <SessionIdContext.Provider value={value}>
      {children}
    </SessionIdContext.Provider>
  );
}

export function useSessionIdContext() {
  const sessionIdContext = useContext(SessionIdContext);

  if (!sessionIdContext) {
    throw new Error(
      'useSessionIdContext must be used within a SessionIdContextProvider',
    );
  }

  return sessionIdContext;
}
